import client from "./Client";
import { Urls } from "./Api-Constant";
/**
 * PC_CB_1.8 to PC_CB_1.9 call client function with required params to get the response
 * PC_CB_1.14 to PC_CB_1.15 return the response
 * @param body
 * @returns access token with bot config
 */
export async function getJwtToken(body: object) {
  let result = await client(Urls.generateJWT, body, "POST","12345678901234567890");
  return result;
}
/**
 * PC_CB_1.17 to PC_CB_1.18 to get the greeting message
 * PC_CB_1.25 return the response
 * @param body
 * @returns the greeting message for the user
 */
export async function getGreeting(body: object) {

  let result = await client(
    Urls.startConversation,
    body,
    "POST",
    "91f3997c2ec2e452"
  );
  return result;
}
/**
 * PC_CB_1.55 to PC_CB_1.62 call client to get the response from API and return the response
 * @param body
 * @returns the bot response
 */
export async function makeAgentRequest(body: object) {
  let result = await client(
    Urls.processConversation,
    body,
    "POST",
    "91f3997c2ec2e452"
  );
  return result;
}
