const devConfig = {
  URLs: {
    generateJWT:
      "https://lonh1xirvi.execute-api.us-east-1.amazonaws.com/tool/generate_token",
    startConversation:
      "http://k8s-aisuperd-superdes-6a8d373b29-1911161964.us-east-1.elb.amazonaws.com/start_conversation",
    processConversation: "https://prod.superdesk.ai/process_conversation",
  },
};

export default devConfig;
