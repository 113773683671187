import { useState } from "react";
export function Choices(data: any) {
  const [disabledValue, setDisabledValue] = useState(false);
  const bindchoics = () => {
    return data?.record.value.map((val: any, index: any) => (
      <>
        {" "}
        <input
          type="radio"
          name="rb"
          disabled={disabledValue}
          id={val.options}
          // className="ai-button-style"
          onClick={(e: any) => {
            setDisabledValue(true);
            data.record.func(e.target.id, "chcs");
          }}
        >
          {val.options}
        </input>
      </>
    ));
  };
  return (
    <>
      <div className="ai-button-container">{bindchoics()}</div>
    </>
  );
}
