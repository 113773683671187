import React, { useState, useEffect, useRef } from "react";
// import { format, parseISO } from "date-fns-tz";
import { v4 as uuidv4 } from "uuid";
import { getJwtToken, getGreeting, makeAgentRequest } from "../Service/Api";
import {
  userDetails,
  ChatMessage,
  ChatConversation,
  BotConfig,
} from "../Interface/Interface";
import BindBotResponse from "./BindBotResponse";
import LoadingIndicator from "../OtherComponents/Loader";
import { log } from "console";

/**
 * PC_CB_1.1 to PC_CB_1.4 Necessary imports and useEffect
 * PC_CB_1.47 to PC_CB_1.49 get the input from the user and store it in a state variable inputValue
 * @returns the chat messages
 */

export function Chatbot() {
  const [loader, setLoader] = useState<boolean>(false);
  const [error, setError] = useState<any>("");
  const [botCss, setBotCss] = useState({ body: false });
  const [inputVal, setInputVal] = useState<any>("");
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const [chatMessages, setChatMessages] = useState<any>({
    chatConversation: [
      {
        data: {
          content: "Hi, I'm SuperDesk - how may I help you?",
          contentType: "txt",
        },
        inputConfig: {
          disableAttachment: 1,
          disableEmoji: 1,
          disableText: 0,
          disableVoice: 1,
          hiddenAttachment: 1,
          hiddenEmoji: 1,
          hiddenText: 0,
          hiddenVoice: 1,
        },
        meta: {
          name: "Superdesk",
          timeStamp: new Date(),
        },
        order: 1,
        role: "bot",
      },
      {
        data: {
          content: [
            {
              options: "ROI",
              selected: false,
            },
            {
              options: "Features of SuperDesk",
              selected: false,
            },
            {
              options: "Overview of SuperDesk",
              selected: false,
            },
          ],
          contentType: "btn",
        },
        inputConfig: {
          disableAttachment: 1,
          disableEmoji: 1,
          disableText: 0,
          disableVoice: 1,
          hiddenAttachment: 1,
          hiddenEmoji: 1,
          hiddenText: 0,
          hiddenVoice: 1,
        },
        meta: {
          name: "Superdesk",
          timeStamp: new Date(),
        },
        order: 2,
        role: "bot",
      },
    ],
    metaData: {
      conversationID: 0,
      entities: {},

      intent: "",
      userData: {
        bot_id: "36e42932-40f7-49b9-b9b6-edd8fa8daca7",
        organization_id: "577d3e38-3c68-4537-836f-0396b9991e3f",
        phoneNo: "+91-877-817-8737",
        userEmail: "JamesAnderson@avatest.in",
        user_id: "ee60e96597f046109e17fa98c253afd5",
        user_name: "test",
      },
    },
  });
  const [botConfig, setBotConfig] = useState<BotConfig>({
    chatbot_name: "Bot",
    chatbot_logo: "",
    enable_file_attachment: false,
    enable_voice: false,
    enable_emoji: false,
  });

  useEffect(() => {
    // need to get the userDetails
    // const fetchData = async () => {
    //   // let val = await generateJwtToken();
    //   // sessionStorage.setItem("access_token", val);
    //   generateGreetingMessage();
    // };
    // fetchData();
    let uuid = uuidv4();
    bindUUID(uuid);
  }, []);
  function bindUUID(uuid: string) {
    const updatedState = { ...chatMessages };

    // Update the user_id value in the metaData userData object
    (updatedState.metaData?.userData ?? {}).user_id = uuid;

    // Set the updated state
    setChatMessages(updatedState);
  }

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  function scrollToBottom() {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }

  /**
   * PC_CB_1.5 to PC_CB_1.7 call getJwtToken to make an API request to get the token
   * PC_CB_1.16 call getGreeting functions to get the greeting message for the user
   * @returns the access token and bot configuration
   */
  const generateJwtToken = async () => {
    try {
      const response = await getJwtToken({
        bot_id: chatMessages?.metaData.userData.bot_id,
      });

      if (response.status == 200) {
        setBotConfig(response.data);
        return response.headers.access_token;
      } else {
      }
    } catch (error: any) {
      console.error("Error generating JWT:", error);
      setError(error.message);
    }
  };
  /**
   * PC_CB_1.26 to PC_CB_1.29 set the response to chatMessage state variable
   * @returns the greeting message
   */
  const generateGreetingMessage = async () => {
    try {
      const response = await getGreeting({ body: chatMessages });

      if (response.status == 200) {
        setChatMessages(JSON.parse(response?.data?.body));
      } else {
      }
    } catch (error) {
      console.error("Error generating greeting message:", error);
    }
  };
  /**
   * PC_CB_1.30 to PC_CB_1.33 duplicate the object in chatMessages and update the
   *  values and add the an object to the chatMessages
   * @param value content
   * @param type content types
   * @returns
   */
  const addUserRequest = (
    value: string,
    type: string,
    role: string
  ): boolean => {
    const lastMessage =
      chatMessages?.chatConversation[chatMessages.chatConversation.length - 1];
    const duplicatedMessage = {
      ...lastMessage,
      order: lastMessage.order + 1,
      role: role,
      meta: {
        ...lastMessage.metaData,
        timeStamp: new Date(),
      },
      inputConfig: {
        ...lastMessage.inputConfig,
        
      },
      data: {
        ...lastMessage.data,
        content: value,
        contentType: "txt",
      },
    };
    if (
      type == "chcs" ||
      type == "multchcs" ||
      type == "dropdwn" ||
      type == "btn"
    ) {
      if (Array.isArray(lastMessage?.data?.content)) {
        lastMessage?.data?.content?.forEach((choice: any) => {
          choice.selected = choice.options === value;
        });
      }
    }
    chatMessages?.chatConversation.push(duplicatedMessage);
    setChatMessages({ ...chatMessages });

    return true;
  };

  /**
   * PC_CB_1.34 to PC_CB_1.37 loop through the chatMessages conversations check for the role
   * PC_CB_1.38 to PC_CB_1.39 bind the bot response by bindBotResponse component
   * PC_CB_1.40 to PC_CB_1.42 bind the user content by dynamic components
   * @returns the chat
   */
const bindChatMessages = () => {
  try {
    if (Object.keys(chatMessages).length != 0) {
      return chatMessages?.chatConversation?.map((val: any, index: number) => {
        let button = val?.data?.contentType;
        let type = chatMessages?.chatConversation[index + 1]?.data?.contentType;

        switch (val.role) {
          case "bot":
            return (
              <>
                <div
                  className={`ai-bot-response-container ${
                    type == "btn" && button == "txt" ? "mrg" : ""
                  }`}
                >
                  {val?.data?.contentType == "btn" ? null : (
                    <img
                      className="ai-bot-avatar"
                      src="images/SuperDesk-teams-logo.png"
                      alt="chatbot avatar icon"
                    />
                  )}

                  <div className="ai-bot-response">
                    <div
                      className={`ai-bot-content  ${
                        button == "btn" ? "ai-bot-content-btn" : ""
                      }`}
                      // style={{ whiteSpace: "pre-wrap" }}
                    >
                      {/* .ai-bot-content-btn */}

                      <BindBotResponse
                        record={{
                          value: val.data,
                          func: handleResponseBinding,
                          message: chatMessages,
                            role:val.role
                        }}
                      />
                    </div>
                    {type == "btn" && button == "txt" ? null : (
                      <span
                        className={` ${
                          ((type == "btn" || type == undefined) &&
                            button == "txt") ||
                          button == "crd"
                            ? "bot-time"
                            : (type == "txt" || type == undefined) &&
                              button == "txt"
                            ? "lt"
                            : (type != "txt" ||
                                type == "btn" ||
                                type == undefined) &&
                              button == "txt"
                            ? "lt"
                            : "lft"
                        }`}
                      >
                        {convertTime(val.meta.timeStamp, "bot")}
                      </span>
                    )}
                  </div>
                </div>
              </>
            );
          case "user":
            return (
              <div className="ai-user-response-container">
                <div className="ai-user-response">
                  <div
                    className="ai-user-content "
                    // style={{ whiteSpace: "pre-wrap" }}
                  >
                    <BindBotResponse
                      record={{
                        value: val.data,
                        func: handleResponseBinding,
                        role:val.role
                      }}
                    />
                  </div>
                  <span className="user-time">
                    {convertTime(val.meta.timeStamp, "bot")}
                  </span>
                </div>
              </div>
            );
        }
      });
    }
  } catch (error) {
    addUserRequest(
      "Something went wrong try again after sometimes",
      "txt",
      "bot"
    );
  }
};
  /**
   * PC_CB_1.43 to PC_CB_1.46 will return the formatted time
   * @param dateTime formatted time
   * @returns
   */
  const convertTime = (dateTime: any, role: string) => {
    if (dateTime) {
      const datetimeObj1 = new Date(dateTime);
      const formattedTime1 = datetimeObj1.toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
      });
      return formattedTime1;
    } else {
      let time = new Date();
      const datetimeObj1 = new Date(time);
      const formattedTime1 = datetimeObj1.toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
      });
      return formattedTime1;
    }

    // Require Moment & Moment-Timezone
  };
  /**
   * PC_CB_1.50 to PC_CB_1.52 to add the user request to the chat by calling addUserRequest()
   * @param value content
   * @param type content type
   * @returns
   */
  const executeSendActions = async (
    value: string,
    type: string,
    role: string
  ) => {
    setLoader(true);
    if (addUserRequest(value, type, role)) {
      if (await executeAgentRequest()) {
        return true;
      }
    } else {
      addUserRequest(
        "Something went wrong Try again after sometimes",
        "txt",
        "bot"
      );
    }
    return false;
  };
  /**
   * PC_CB_1.53 to to PC_CB_1.54 call makeAgentRequest to get the response for user request
   * PC_CB_1.63 to PC_CB_1.64 set the response to the chatMessages variable
   * @returns
   */
  const executeAgentRequest = async (): Promise<boolean> => {
    try {
      const response = await makeAgentRequest({ body: chatMessages });


      if (
        response.status &&
        response.status == 200 &&
        response.data?.body != "null"
      ) {
        setChatMessages(JSON.parse(response.data?.body));
        setLoader(false);
        return true;
      } else {
        setLoader(false);
        addUserRequest("Something went wrong try again", "txt", "bot");
        return false;
      }
    } catch (error) {
      console.error("Error executing agent request:", error);
      return false;
    }
  };
  /**
   * PC_CB_1.65 to PC_CB_1.69 get the user request from other components and bind it to the chat
   * @param value
   * @param type
   */
  const handleResponseBinding = (value: any, type: string) => {
    if (value != "") {
      if (type == "multchc") {
        let val = value.join(",");
        executeSendActions(val, type, "user");
      } else {
        executeSendActions(value, type, "user");
      }
    }
  };
  return (
    <>
      <div className="ai-container" hidden={botCss.body}>
        {/* <div className="ai-header">
          <div className="ai-title-container">
            <img
              className="ai-logo"
              src={
                botConfig?.chatbot_logo
                  ? botConfig?.chatbot_logo
                  : "images/SuperDesk-teams-logo.png"
              }
              alt="chatbot logo"
            />
            <h1 className="ai-title">{botConfig?.chatbot_name}</h1>
          </div>
          <div className="ai-action-container">
            <img
              className="ai-minimize ai-header-icon"
              src="images/minimize-icon.svg"
              alt="Minimize icon"
              onClick={(e) => {
                // setBotCss({ ...botCss, body: true });
              }}
            /> */}
        {/* <img
              className="ai-maxwindow ai-header-icon"
              src="images/bigwindow-icon.svg"
              alt="Max window icon"
            />
            <img
              className="ai-close ai-header-icon"
              style={{
                cursor: "pointer",
              }}
              src="images/close-icon.svg"
              alt="close icon"
              onClick={(e) => {
                setBotCss({ ...botCss, body: true });
                setChatMessages((prevChatMessages: any) => ({
                  ...prevChatMessages,
                  chatConversation: [],
                }));
              }}
            // /> */}
        {/* </div>
        </div> */}
        <div className="ai-body">
          <div className="ai-content-container" ref={chatContainerRef}>
            <></>
            {Object.keys(chatMessages).length ? bindChatMessages() : null}
            {loader ? <LoadingIndicator /> : null}
            {error ? <span>{error}</span> : null}
          </div>
          <div className="ai-downarrow">
            <i className="fa-solid fa-arrow-down"></i>
          </div>
        </div>
        <div className="ai-footer">
          <div className="ai-voice-input">
            <img
              hidden={
                Array.isArray(chatMessages?.chatConversation)
                  ? chatMessages?.chatConversation[
                      chatMessages?.chatConversation?.length - 1
                    ]?.inputConfig?.hiddenVoice == 1
                    ? true
                    : chatMessages?.chatConversation[
                        chatMessages?.chatConversation?.length - 1
                      ]?.inputConfig?.hiddenVoice == 0
                    ? false
                    : botConfig?.enable_voice == true
                    ? true
                    : botConfig?.enable_voice == false
                    ? true
                    : true
                  : false
              }
              style={{
                cursor: Array.isArray(chatMessages?.chatConversation)
                  ? chatMessages?.chatConversation[
                      chatMessages?.chatConversation?.length - 1
                    ]?.inputConfig?.disableVoice == 1
                    ? "not-allowed"
                    : "pointer"
                  : "not-allowed",
                opacity: Array.isArray(chatMessages?.chatConversation)
                  ? chatMessages?.chatConversation[
                      chatMessages?.chatConversation?.length - 1
                    ]?.inputConfig?.disableVoice == 1
                    ? 0.5
                    : 1
                  : 1,
              }}
              className="ai-audio-icon"
              src="images/voice-input-icon.svg"
              alt="Audio icon"
            />
          </div>
          <div className="ai-txt-input">
            <input
              value={inputVal}
              disabled={
                Array.isArray(chatMessages?.chatConversation)
                  ? chatMessages?.chatConversation[
                      chatMessages?.chatConversation?.length - 1
                    ]?.inputConfig?.disableText == 1
                    ? true
                    : chatMessages?.chatConversation[
                        chatMessages?.chatConversation?.length - 1
                      ]?.inputConfig?.disableText == 0
                    ? false
                    : true
                  : true
              }
              hidden={
                Array.isArray(chatMessages?.chatConversation)
                  ? chatMessages?.chatConversation[
                      chatMessages?.chatConversation?.length - 1
                    ]?.inputConfig?.hiddenText == 1
                    ? true
                    : false
                  : false
              }
              onKeyUp={(e) => {
                if (e.key == "Enter") {
                  if (inputVal != "") {
                    executeSendActions(inputVal, "txt", "user");
                    setInputVal("");
                  }
                }
              }}
              onChange={(e) => {
                setInputVal(e.target.value);
              }}
              className="ai-input-control"
              type="text"
              placeholder="Ask me something..."
            />
          </div>
          {/* <div className="ai-attachment">
            <div className="ai-attachment-container">
              <label htmlFor="fileInput" className="ai-attachment-icon">
                <img
                  src="images/attachment-icon.svg"
                  alt="attachment-icon"
                  hidden={
                    chatMessages?.chatConversation[
                      chatMessages?.chatConversation?.length - 1
                    ]?.inputConfig?.hiddenVoice == 1
                      ? true
                      : chatMessages?.chatConversation[
                          chatMessages?.chatConversation?.length - 1
                        ]?.inputConfig?.hiddenVoice == 0
                      ? false
                      : botConfig?.enable_file_attachment == true
                      ? true
                      : botConfig?.enable_file_attachment == false
                      ? true
                      : true
                  }
                  style={{
                    cursor:
                      chatMessages?.chatConversation[
                        chatMessages?.chatConversation?.length - 1
                      ]?.inputConfig?.disableAttachment == 1
                        ? "not-allowed"
                        : "pointer",
                    opacity:
                      chatMessages?.chatConversation[
                        chatMessages?.chatConversation?.length - 1
                      ]?.inputConfig?.disableAttachment == 1
                        ? 0.5
                        : 1,
                  }}
                />
              </label>
              <input type="file" id="fileInput" style={{ display: "none" }} />
            </div>
            <div
              className="ai-selected-file-container"
              id="selectedFileContainer"
            >
              <span id="selectedFileName" />
              <span className="ai-remove-icon">✖</span>
            </div>
          </div> */}
          <div className="ai-send">
            <img
              className="ai-send-icon"
              src="images/send-icon.svg"
              alt="Send icon"
              style={{
                cursor: Array.isArray(chatMessages?.chatConversation)
                  ? chatMessages?.chatConversation[
                      chatMessages?.chatConversation?.length - 1
                    ]?.inputConfig?.disableText == 1
                    ? "not-allowed"
                    : chatMessages?.chatConversation[
                        chatMessages?.chatConversation?.length - 1
                      ]?.inputConfig?.disableText == 0
                    ? "pointer"
                    : "not-allowed"
                  : "not-allowed",
                opacity: Array.isArray(chatMessages?.chatConversation)
                  ? chatMessages?.chatConversation[
                      chatMessages?.chatConversation?.length - 1
                    ]?.inputConfig?.disableText == 1
                    ? 0.5
                    : chatMessages?.chatConversation[
                        chatMessages?.chatConversation?.length - 1
                      ]?.inputConfig?.disableText == 0
                    ? 1
                    : 0.5
                  : 0.5,
              }}
              onClick={(e) => {
                if (inputVal != "") {
                  executeSendActions(inputVal, "txt", "user");
                  setInputVal("");
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
