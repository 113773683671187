import { useState } from "react";
export function DropDown(data: any) {
  const [disabledValue, setDisabledValue] = useState(false);
  const bindOptions = () => {
    return data?.record.value.map((val: any, index: Number) => {
      return (
        <>
          <option>{val.options}</option>
        </>
      );
    });
  };
  return (
    <>
      <select
        className="dropdown"
        disabled={disabledValue}
        onChange={(e) => {
          if (e.target.value != "Select" && e.target.value != "") {
            setDisabledValue(true);
            data.record.func(e.target.value, "drpdwn");
          }
        }}
        id="options"
      >
        <option>Select</option>
        {bindOptions()}
      </select>
    </>
  );
}
