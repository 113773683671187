import { useState } from "react";

export function MultiChoices(data: any) {
   const [disabledValue, setDisabledValue] = useState(false);
  const [choosenOptions, setChoosenOptions] = useState<any>([]);
  const bindchoics = () => {
    return data?.record.value.map((val: any, index: any) => (
      <>
        {" "}
        <input
          type="checkbox"
          value={val.options}
          disabled={disabledValue}
          // className="ai-button-style"
          onClick={(e: any) => {
            if (e.target.value) {
              setDisabledValue(true);
              let choosenOption = choosenOptions;
              // sethideError(false);

              if (e.target.checked) {
                choosenOption.push(e.target.value);
              } else {
                let index = choosenOption.indexOf(e.target.value);

                {
                  index != -1
                    ? choosenOption.splice(
                        choosenOption.indexOf(e.target.value),
                        1
                      )
                    : console.log(choosenOption);
                }
              }
              setChoosenOptions(choosenOption);
              data.record.func(choosenOption, "multchc");
            }
          }}
        >
          {val.options}
        </input>
      </>
    ));
  };
  return (
    <>
      <div className="ai-button-container">{bindchoics()}</div>
    </>
  );
}
