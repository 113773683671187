export function Table(data: any) {
  // const keys = Object.keys(data?.record.value);
  // const values = Object.values(data?.record.value);
  const entries = Object.entries(data?.record.value);
  return (
    <>
      <div className="ai-bot-response ai-table-container">
        <table className="ai-table">
          <tbody>
            {entries.map((entry: any, index) => (
              <tr className="ai-table-tr" key={index}>
                <td className="ai-table-body ai-td-label keys-align">
                  {entry[0].charAt(0).toUpperCase() + entry[0].slice(1)}
                </td>
                <td className="ai-table-body ai-td-label values-align ai-table-content">
                  {entry[1]}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
