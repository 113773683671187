import axios from "axios";

async function client(endpoint: string, body: any, requestType: string, apikey: string) {
  
  try {
    let url = `${endpoint}`;

  
    let config = {
      method: requestType,
      url: endpoint,
      data: body,
      headers: {
        // "api-key": "91f3997c2ec2e452",
        "api-key": apikey,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization:
          "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyRGV0YWlscyI6IHsiYm90X2lkIjogIjM2ZTQyOTMyLTQwZjctNDliOS1iOWI2LWVkZDhmYThkYWNhNyJ9fQ.Rot0CcsUAj8aYDfKbszCl4YhZhSAcdAVW0pG5zLUPaM",
        // access_token: sessionStorage.getItem("access_token"),
      },
    };


    const results = await axios(config);



    return results;

  } catch (error:any) {
    console.log(error.message);
    return error
  
  }
}
export default client;
